@import '../custom';

.page-not-found {
  text-align: center;
  .error-page-text {
    color: $tertiary;
    font-weight: 500;
    font-size: 18px;
  }
}
.not-found-image {
  width: 32%;
}
@media only screen and (max-width: 575px) {
  .page-heading-center {
    font-size: 20px !important;
  }
  .not-found-image {
    width: 50% !important;
  }
}
