@use '../custom';

.dropzone {
  min-height: 150px !important;
  border: 2px solid $secondary !important;
  background: transparent !important;
  padding: 20px 20px !important;
  border-radius: 10px !important;
  .card span {
    color: #fff !important;
  }
  .dz-preview .dz-image img {
    display: block;
    /* width: 100%; */
    /* height: auto; */
    height: 120px;
    width: 100%;
  }
}
