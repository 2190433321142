@use "../custom" as *;

.inner-box {
    margin: 0 auto;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08) !important;
    .edit-profile-img {
        display: block;
        margin: 0 auto;
        height: 100px;
        width: 100px;
        border-radius: 100%;
        text-align: center;
        position: relative;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
        }
    }
    .upload-profile {
        text-align: center;
        font-weight: 500;
        color: $tertiary;
        margin-top: 10px;
        label {
            text-decoration: underline;
            color: $primary;
            cursor: pointer;
          }
    }
}
.edit-profile-form {
    margin: 0 auto;
    max-width: 713px;
    width: 100%;
}
.contry-code {
    height: 45px;
}
.contant-number {
    background-color: white !important;
    width: 90% !important;
    height: 45px;
}
.stapper-form{
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}
.company-rate-page {
    margin: 0 5px 0.5rem 0;
    
  }
@media only screen and (max-width: 991px) {
    .inner-box {
        margin: 0 auto;
        max-width: 95%;
        paddin: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .inner-box {
        margin: 0 auto;
        max-width: 100%;
    }
    .edit-profile-form {
        padding: 0% 3%;
    }
    .contant-number {
        width: 100% !important;
    }
}
