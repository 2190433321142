@use "../custom";

.accordion-button {
    color: $primary !important;
    background-color: #fff !important;
    box-shadow: none;
    font-weight: 600;
}
.accordion-button:not(.collapsed) {
    color: $primary !important;
    background-color: #fff !important;
    box-shadow: none;
    font-weight: 600;
}
.accordion-button:focus {
    box-shadow: none !important;
}
.accordion-button:hover {
    z-index: 0 !important;
}
.wizard-steps {
    position: relative;
    z-index: 1;
  }
  .wizard-step-text {
    position: relative;
    top: 50px;
    font-weight: 500;
    color: $tertiary;
    text-align: center;
    // white-space: nowrap;
  }
  .process .wizard-step-text {
    font-weight: 600;
    color: $primary;
  }
  
  .progress-container {
    display: flex;
    margin: 3% auto;
    justify-content: space-between;
    position: relative;
    margin-bottom: 80px;
    width: 90%;
  
    &::before {
      content: '';
      position: absolute;
      height: 5px;
      left: 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 99%;
      z-index: -1;
      background-color: $primary;
    }
    .circle {
      background-color: $white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex !important;
      align-items: center !important;
      justify-content: center;
      border: 4px solid $primary;
      transition: 0.4s ease;
      cursor: pointer;
    }
    .process {
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $primary !important;
      }
    }
    .active {
      border-color: $primary;
      background-color: $primary;
      color: $white;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .progress-container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .logout-icon {
      svg {
        padding-right: 0px;
      }
    }
    .wizard-steps {
      width: 100%;
      overflow-y: scroll;
    }
    .progress-container {
      width: 560px;
      margin-left: 20px;
    }
    .wizard-steps::-webkit-scrollbar {
      height: 2px;
      width: 0px;
    }
    .wizard-steps::-webkit-scrollbar-thumb {
      box-shadow: inset 113px 0px 0px 0px $primary;
    }
    .wizard-steps::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 10px $view-text;
    }
  }
